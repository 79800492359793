<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('share.copyuser')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
        <van-row  style="margin: 0 auto;margin-top:15rem;" id="qrCode"></van-row>
        <van-row class="image-body" style="margin: 0 auto;margin-top:7rem;">
          <span style="color:#FFF">{{ $t('share.tips')}}</span>
          <van-button @click.prevent="copyurl()" class="icon-copy" :data-clipboard-text="this.shareurl">
          {{$t('share.copyurl')}}
          </van-button>
        </van-row>
    </div>
  </div>
</template>
<script>

import Clipboard from 'clipboard'
import QRCode from  'qrcodejs2'

export default {
  data() {
    return {
      shareurl:''
    }
  },
  created() {
    this.getinfo()
  
  },
  methods: {
     creates(){
          this.$nextTick(() => {
              new QRCode(document.getElementById('qrCode'), {
                text: this.shareurl,
                width:150,
                height:150,
                colorDark: "#333333", //二维码颜色
                colorLight: "#ffffff", //二维码背景色
                correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
              });
        });
    },
    async getinfo() {
      const { data } = await this.$http.get('/home/user/share')
      if (data) {
        if (data.code === 200) {
          this.shareurl = data.data.url
            this.creates()
        }
      }
    },
    // 复制地址
    copyurl() {
      const clipboard = new Clipboard('.icon-copy')
      clipboard.on('success', e => {
        this.$toast.success(this.$t('common.success'))
      })
    },
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding-top: 65px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background-image:url('../../assets/img/share_bg.jpg');
  background-position: center center; /* 水平垂直居中 */
  background-size: cover; /* 背景图片覆盖整个元素 */
  background-repeat: no-repeat; /* 不重复背景图片 */
  text-align: center;
  .van-cell__title {
    text-align: left;
    font-size: 0.93333rem;
  }
  .van-cell {
    font-size: 0.93333rem;
    background-color: #fff !important;
    height: 3.2rem !important;
    &:after {
      border-bottom: 0.02667rem solid #d0d0d1;
    }
  }
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1rem;
    background: transparent !important;
  }
  .van-dropdown-menu /deep/ .van-dropdown-menu__title {
    color: #212121 !important;
  }
  /deep/.van-dropdown-item {
    margin-top: 1.1rem;
  }
  .van-dropdown-menu /deep/ .van-cell {
    font-size: 0.98667rem;
    background-color: #fff !important;
    height: 3.46667rem;
  }
  .show-img {
    width: 6.4rem;
    margin-top: 0.8rem;
    height: 6.4rem;
  }
  .image-body {
    .van-button {
      color: #fff;
      background-color: #000;
      margin: 0.53333rem 0 1.33333rem 0;
      border: none;
      border-radius: 0.45333rem;
      height: 1.86667rem;
      //width: 8rem;
    }
    h3 {
      color: #000;
      font-size: 1.05rem;
    }
    span {
      display: block;
      margin: 0.53333rem;
    }
  }
  .van-field {
    height: 3.4rem;
    font-size: 0.93333rem;
    /deep/.van-field__label {
      color: #323232;
      width:8rem;
    }
  }
  .van-cell__value {
    position: relative;
    overflow: hidden;
    color: #969799;
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word;
  }
  /deep/.van-field__error-message--right,
  /deep/.van-field__control,
  /deep/.van-field__error-message {
    text-align: right;
  }
  .message {
    margin-top: 0.8rem;
    font-size: 1.06667rem;
    color: #000;
  }
  .uploading-image {
    height: 13.33333rem;
    width: 90%;
    margin: 2% 5% 5% 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.02667rem dashed grey;
    .van-uploader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      /deep/.van-uploader__wrapper {
        width: 100%;
        height: 100%;
        justify-content: center;
      }
      /deep/.van-uploader__upload {
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: #fff;
        color: #fff;
      }
    }
  }
  .submitBtns {
    margin-bottom: 20%;
    margin-top: 10%;
    width: 80%;
    letter-spacing: 0.53333rem;
    height: 4rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #004ea3;
    border-radius: 0.53333rem;
    border: none;
    box-shadow: 0 0 1.33333rem #004ea3;
  }
}
</style>
